import React, {useEffect, useState} from "react";
import Box from '@material-ui/core/Box';
import {headerLogoStyles} from '../../css/styles'
import Container from "@material-ui/core/Container";
import logo from '../../img/esimplify.png';
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import {signOut, loader_add, setColor, updateWhiteLabelColor, setLogoutStatus, wlLoader_remove} from '../../actions/actions';
import NavBarHeader from "./NavBarHeade";
import MobileHeader from './MobileHeader';
import CompanyLogo from './CompanyLogo';
import ChangeBgColorBtn from './ChangeBgColorBtn';

export default () => {
    const classes = headerLogoStyles();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const isMfaSetuped = useSelector(state => state.isMfaSetup);
    const companyAccess = useSelector(state => state.companyAccess);
    const whiteLabel = useSelector((state) => state.whiteLabel);
    const isWLLoading = useSelector((state) => state.wlLoader);
    const isSubdomainWrong = useSelector((state) => state.isSubdomainWrong);
    const headerColor = useSelector(state => state.colors?.colors?.headerColor || "#02c9e3");
    const footerColor = useSelector(state => state.colors?.colors?.footerColor || "#383939");
    const headerFontColor = useSelector(state => state.colors?.colors?.headerFontColor || "#ffffff");
    const footerFontColor =useSelector(state => state.colors?.colors?.footerFontColor || "#d3d3d3");
    const whiteLabelId = whiteLabel?.id;
    const whiteLabelLogo = whiteLabel?.logoUrl;

    let history = useHistory();
    const [mobile, setMobile] = useState(false);
    const [wlLogo, setWlLogo] = useState(whiteLabelLogo);

    useEffect(() => {
        if(426 >= window.innerWidth) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, []);

    useEffect(() => {
      setWlLogo(whiteLabelLogo)
    }, [whiteLabelLogo])


    const logout = (e) => {
        dispatch(loader_add());
        dispatch(signOut(dispatch, history));
        if(companyAccess==="whiteLabel"){
            dispatch(setLogoutStatus(true));
        }
        dispatch(wlLoader_remove())
    };

    const handleChangeComplete = (color)=>{
        dispatch(setColor({headerColor:color.hex, footerColor, footerFontColor, headerFontColor}));
    }

    const handleChangeCompleteFooter = (color)=>{
        dispatch(setColor({footerColor:color.hex, headerColor, footerFontColor, headerFontColor}));
    }

    const handleChangeFooterFontColor = (color)=>{
        dispatch(setColor({footerFontColor:color.hex, headerColor, footerColor, headerFontColor}));
    }

    const handleChangeHeaderFontColor = (color)=>{
        dispatch(setColor({headerFontColor:color.hex, headerColor, footerColor, footerFontColor}));
    }

    const handleSaveChanges =()=>{
        if(whiteLabelId && !isWLLoading){
          dispatch(updateWhiteLabelColor(whiteLabelId, {headerFontColor, headerColor, footerColor, footerFontColor}, dispatch));
        }
    }


    if(null === user || isWLLoading) {
        return <div></div>;
    }


    const isMfaSetup = () => {
        if (undefined === user || undefined === user.attributes) {
            return false;
        }

        return (user.attributes['phone_number_verified'] || 
            (null !== isMfaSetuped && undefined !== isMfaSetuped.TOTP && undefined !== isMfaSetuped.SMS_MFA));
    };

    if(isSubdomainWrong) {
            return (
                <Box width="100%" bgcolor={companyAccess==="whiteLabel" && headerColor ?  headerColor: "#02c9e3"} minHeight= "60px">
                    <Container  style={{width: '95%'}}>
                        {companyAccess!=="whiteLabel" ?
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                        {isWLLoading ? null: companyAccess!=="whiteLabel" && 
                        <div className={classes.root}><img className={classes.logo} src={logo} alt="logo"/> </div>}
                        </Box> :
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                        {isWLLoading? null: companyAccess==="whiteLabel" && 
                        <div className={classes.root}><img className={classes.logo} src={wlLogo} alt="logo"/> </div>}
                        </Box>
                        }
                    </Container>
                </Box>
            );
    };


    if(null !== user || isSubdomainWrong) {
        if (false === user.userConfirmed || 'not authenticated' === user || undefined === user.attributes) {
            return (
                <Box width="100%" bgcolor={companyAccess==="whiteLabel" && headerColor ?  headerColor: "#02c9e3"} minHeight= "60px">
                    <Container  style={{width: '95%'}}>
                        {companyAccess!=="whiteLabel" ?
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                        {isWLLoading ? null: companyAccess!=="whiteLabel" && 
                        <div className={classes.root}><img className={classes.logo} src={logo} alt="logo"/> </div>}
                        </Box>:
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                        {isWLLoading? null: companyAccess==="whiteLabel" && 
                        <div className={classes.root}><img className={classes.logo} src={wlLogo} alt="logo"/> </div>}
                        </Box>
                        }
                    </Container>
                </Box>
            );
        }
    };

    if (undefined !== user.preferredMFA && "NOMFA" === user.preferredMFA && false === isMfaSetup() ) {
            return <Box width="100%" bgcolor={companyAccess==="whiteLabel" && headerColor ? headerColor : "#02c9e3"} minHeight= "60px">
                        <Container  style={{width: '95%'}}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                            {isWLLoading? null: companyAccess!=="whiteLabel" && 
                            <div className={classes.root}><img className={classes.logo} src={logo} alt="logo"/> </div>}
                                { isWLLoading ? null :<Box className={classes.logout} onClick={e => logout(e)} 
                                style={{color: companyAccess==="whiteLabel" && headerFontColor ? headerFontColor : "#ffffff"}}>
                                    Logout
                                </Box>}
                            </Box>
                        </Container>
                    </Box>
    }

    if(true === mobile && 'not authenticated' !== user) {
        return <MobileHeader mobile={mobile}/>
    }

    const userName = (attributes) => {
        let userName = '';
        let lastName = '';
        if(!!attributes['custom:lastName']) {
            lastName = attributes['custom:lastName'];
        }
        if(!!attributes['custom:firstName']) {
            userName = attributes['custom:firstName'];
        }

        return <div>{userName} {lastName}</div>;

    };

    return (<Box width="100%" bgcolor={whiteLabel && companyAccess==="whiteLabel" && headerColor ? headerColor : "#02c9e3"} minHeight= "60px">
                <Container  maxWidth={"xl"}>
                    {isWLLoading ? null:
                    <Box  style={{marginLeft: 'auto', marginRight: 'auto', display: "flex", alignItems: "center", 
                        justifyContent: companyAccess!=="whiteLabel" ? "space-between" : "flex-start"}}>
                        {('not authenticated' !== user ) ? 
                            <div style={{color: companyAccess==="whiteLabel" && headerFontColor ? headerFontColor :'#ffffff', padding: '10px'}}>
                                Welcome {userName(user.attributes)}</div> : null}
                        {isWLLoading? null: companyAccess!=="whiteLabel" && <div style={{padding: '16px 30px', flexGrow: 1}} 
                            className={classes.root}><img className={classes.logo} src={logo} alt="logo"/> </div>}
                        <CompanyLogo />
                        <Box style={{width: companyAccess!=="whiteLabel" ? "auto": "100%", display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            <ChangeBgColorBtn
                              user={user}
                              handleChangeComplete={handleChangeComplete}
                              handleChangeCompleteFooter={handleChangeCompleteFooter}
                              headerColor={headerColor}
                              footerColor={footerColor}
                              handleChangeHeaderFontColor={handleChangeHeaderFontColor}
                              handleChangeFooterFontColor={handleChangeFooterFontColor}
                              headerFontColor={headerFontColor}
                              footerFontColor={footerFontColor}
                              handleSaveChanges={handleSaveChanges}            
                              />
                          {('not authenticated' !== user )
                              ?   isWLLoading ? null :<Box className={classes.logout} onClick={e => logout(e)} 
                                style={{color: companyAccess==="whiteLabel" && headerFontColor ? headerFontColor : "#ffffff"}}>
                                      Logout
                                  </Box>
                              : null}

                        </Box>
                    </Box>}
                 </Container>
               {('not authenticated' !== user )
                   ? isWLLoading ? null :<Container maxWidth={"xl"} 
                   style={{background:companyAccess==="whiteLabel" && whiteLabel && headerColor ? headerColor : 'rgba(0, 165, 181, 0.25)'}}>
                       <NavBarHeader mobile={mobile}/>
                    </Container>
                   : null}
            </Box>)
}