import io from 'socket.io-client';
let socket;

export const initiateSocket = (userId) => {
  socket = io('https://beta.esimplifysolutions.us', {
    path: '/websocket/socket.io',
    transports: ['polling']
  });
  console.log(`Connecting socket...`);
  console.log({
    socket,
    userId
  });
  if (socket && userId) socket.emit('link-connection-to-user', userId);
}

export const disconnectSocket = () => {
  console.log('Disconnecting socket...');
  if (socket) socket.disconnect();
}

export const subscribeTo = (event, cb) => {
  if (!socket) return;

  socket.on(event, msg => {
    return cb(null, msg);
  });
}

export const sendMessage = (event, payload) => {
  if (!socket) return;

  socket.emit(event, payload);
}