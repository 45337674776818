import SearchParamsFactory from './SearchParamsFactory';

export default class ApiClient {

    /**
     * @param {String} baseUrl
     */
    constructor(baseUrl = 'https://beta.esimplifysolutions.us')

    {
        this.baseUrl = baseUrl;
        this._searchParamsFactory = new SearchParamsFactory();
    }

    /**
     * @param {Response} response
     * @return {Response}
     * @private
     */
    _filterErrorResponse(response)
    {
        if (response.status >= 400) {
            return Promise.reject({
                error: 'internal server error',
                response: response
            });
        }

        return response;
    }

    /**
     * @param {Response} response
     * @return {Response}
     * @private
     */
    _filterNonJsonResponse(response)
    {
        if (false === response.headers.has('content-type')) {
            return Promise.reject('unknown response content-type');
        }

        if (-1 === response.headers.get('content-type').indexOf('json')) {
            return Promise.reject('response is not json');
        }

        return Promise.resolve(response);
    }

    /**
     * @param {string} endpoint
     * @return {String}
     * @private
     */
    _makeApiUrl(endpoint)
    {
        if (-1 < endpoint.indexOf('https')) {
            return endpoint;
        }

        if ('/' !== endpoint.charAt(0)) {
            return `${this.baseUrl}/${endpoint}`;
        }

        return `${this.baseUrl}${endpoint}`;
    }

    /**
     * @param {String} endpoint
     * @param {String} method
     * @param {FormData|null} body
     * @param {Object} headers
     * @return {Promise}
     * @private
     */
    _sendRequest(endpoint, method, body, headers, addOptions = null, query = false)
    {
        const options = {
            method: method,
            redirect: 'follow',
            referrerPolicy: 'no-referrer'

        };

        if (null !== addOptions) {
           for(let key in addOptions) {
               options[key] = addOptions[key];
           }
        }


        let url = this._makeApiUrl(endpoint);

        if (body) {
            Object.keys(body).forEach(key => key === 'undefined' && delete body[key]);
            Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);

            if ('GET' === method || 'HEAD' === method || query === true) {

                const searchParams = this._searchParamsFactory.createSearchParams(body);
                const searchString = searchParams.toString();
                if (searchString) {
                    url += (-1 === url.indexOf('?')) ? '?' + searchString : '&' + searchString;
                }

            } else {

                if (headers && headers['Content-type'] && -1 < headers['Content-type'].indexOf('json')) {
                    body = JSON.stringify(body);
                }
                if(null !== body) {
                    options.body = body;
                }

            }
        }

        if (headers) {

            options.headers = headers;
        }

        return fetch(url, options)
            .then(response => this._filterErrorResponse(response))
            ;
    }
};
